.hero-space-selection {
  @media (max-width: 1023px) {
    h1,
    p,
    a {
      @apply text-center;
    }
  }

  .hero--cover {
    @apply absolute top-0 left-0 h-full w-full;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4) 0%;
  }
}

.mux-player {
  --controls: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --media-background-color: rgb(255 255 255 / 100%);
}

.video-cover {
  --media-object-fit: cover;
}
